import {ClassProvider, ErrorHandler, Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpRequest} from '@angular/common/http';
import {GqlTokenRefreshInterceptorService} from './gql-token-refresh-interceptor.service';
import {catchError, map, Observable, switchMap, throwError} from 'rxjs';
import {AUTH_SERVICE} from '@px/shared/api';

/**
 * Interceptor in charge to deal with requests to the Pic and Cloud Proofing APIs
 */
@Injectable({providedIn: 'root'})
export class CombinedTokenRefreshInterceptorService extends GqlTokenRefreshInterceptorService {
  private isRegularPICRequest(req: HttpRequest<unknown>): boolean {
    const isPic = req.url.startsWith(this.platform.API_URL);

    if (!isPic) {
      return false;
    }

    switch (req.url) {
      case `${this.platform.API_URL}jwt-authorization/`:
      case `${this.platform.API_URL}check-login/`:
      case `${this.platform.API_URL}logout/`:
        return false;
      default:
        return true;
    }
  }

  private isRegularCPRequest(req: HttpRequest<unknown>): boolean {
    return req.url.startsWith(this.platform.CLOUD_PROOFING_API_URL);
  }

  protected override toIntercept(req: HttpRequest<unknown>): boolean {
    return super.toIntercept(req) || this.isRegularCPRequest(req) || this.isRegularPICRequest(req);
  }

  protected override getRefreshingChain(): Observable<void> {
    return super.getRefreshingChain().pipe(
      catchError(err => {
        console.error(err);

        this.injector.get(ErrorHandler).handleError(err);
        return throwError(() => err);
      }),
      switchMap(() => {
        const service = this.sessionProviderFacade.getSessionService();
        const authService = this.injector.get(AUTH_SERVICE);

        if (!service?.session) {
          return throwError(() => new Error("Session or SessionProvider doesn't exist!"));
        }

        return authService
          .jwtAuthorize({
            jwt: service.session?.token as string,
            refresh_id: service.session?.refreshId as string,
            expiration: service.session?.expiration as number,
          })
          .pipe(map(() => undefined));
      })
    );
  }
}

export const PIC_TOKEN_INTERCEPTOR: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: CombinedTokenRefreshInterceptorService,
  multi: true,
};
