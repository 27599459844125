import {inject, NgModule, provideAppInitializer} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {
  ApolloCachePersistService,
  ApolloOptionResolverBatchService,
  ApolloOptionsModule,
  ApolloOptionsResolverService,
  GQL_CACHE_TOKEN,
} from '@px/shared/data-access/apollo-options';
import {ApolloCache, ApolloClientOptions, NormalizedCacheObject} from '@apollo/client';
import {PSSThemesPlatformEnvironment} from '@px/pss-themes/platform-env';

@NgModule({
  imports: [ApolloModule, ApolloOptionsModule],
  providers: [
    ApolloOptionResolverBatchService,
    ApolloOptionsResolverService,
    {
      provide: GQL_CACHE_TOKEN,
      useFactory: (): ApolloCache<NormalizedCacheObject> => inject(ApolloCachePersistService).getCache(),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (): ApolloClientOptions<NormalizedCacheObject> => {
        const apolloOptionResolverBatchService = inject(ApolloOptionResolverBatchService);
        const apolloOptionsResolver = inject(ApolloOptionsResolverService);
        const platform = inject(PSSThemesPlatformEnvironment);

        if (platform.GQL_BATCH) {
          return apolloOptionResolverBatchService.resolve();
        }

        return apolloOptionsResolver.resolve();
      },
    },
    provideAppInitializer(() => {
      const initializerFn = ((): (() => Promise<void>) => {
        const platform = inject(PSSThemesPlatformEnvironment);

        if (!platform.GQL_PERSIST) {
          return (): Promise<void> => Promise.resolve();
        }

        const apolloOptionPersistResolver = inject(ApolloCachePersistService);

        return (): Promise<void> => {
          return apolloOptionPersistResolver.setupPersistStore();
        };
      })();
      return initializerFn();
    }),
  ],
})
export class AppApolloModule {}
