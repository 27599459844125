import {Injectable} from '@angular/core';
import {ISessionProvider} from '../entities/session-provider-service.interface';

/**
 * Why?
 * The problem is that we have two user roles on psf-themes. And sessions are needed for each of the roles. But since the API of these services is similar, one interface is used to work with both services.
 */
@Injectable({providedIn: 'root'})
export class SessionProviderFacade {
  private service: ISessionProvider | undefined;

  getSessionService(): ISessionProvider | undefined {
    return this.service;
  }

  attach(sessionProviderFacade: ISessionProvider): void {
    this.service = sessionProviderFacade;
  }

  detach(): void {
    this.service = undefined;
  }
}
