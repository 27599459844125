export interface IPICImageLinkData {
  userId: number | null;
  active_storage_service: string;
  image_path: string;
  fullLink: string | null;
}

export class ImageLinkParser {
  static parseImageLinkToPicImageLinkData(fullLink?: string): IPICImageLinkData {
    if (!fullLink) {
      return {
        userId: null,
        active_storage_service: 'aws',
        image_path: '',
        fullLink: null,
      };
    }

    const regex =
      /https:\/\/[^/]+\/(?<active_storage_service>[^/]+)\/[^/]+\/[^/]+\/(?<userId>[^/]+)\/(?<image_path>[^?]+)/;

    const match = fullLink.match(regex);

    if (!match || !match.groups) {
      throw new Error('Invalid link format');
    }

    const {userId, active_storage_service, image_path} = match.groups;

    return {
      userId: Number.parseInt(userId, 10),
      active_storage_service,
      image_path,
      fullLink,
    };
  }
}
