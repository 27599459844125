import {provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom, inject} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PreloadAllModules, provideRouter, withPreloading} from '@angular/router';
import {WINDOW_PROVIDER} from '@px/cdk/window';
import {ConnectionIssueDomainModule} from '@px/connection-issue/domain';
import {AppTranslateModule} from '@px/pss-themes/feature-translate';
import {PxEventsApiModule, PxEventsGraphqlApiService} from '@px/px-events/api';
import {PX_EVENTS_API, PxEventsDomainModule} from '@px/px-events/domain';
import {
  BACKEND_CONTEXT,
  BackendContextService,
  SharedDataAccessBackendModule,
} from '@px/shared-data-access-backend-context';
import {
  DEFAULT_STORAGE_SALT,
  ILocalStorageService,
  LOCAL_STORAGE_SALT,
  LocalStorageService,
} from '@px/shared-data-access-local-storage';
import {ApiModule} from '@px/shared/api';
import {BrowserService} from '@px/shared/browser';
import {DATA_DOG_SETUP_INITIALIZER, DataDogService} from '@px/shared/data-access/data-dog';
import {
  CONFIG_CAT_ASYNC_INITIALIZER,
  ConfigCatAsyncProvider,
  FEATURE_FLAGS_CONFIGURATOR_PROVIDER,
} from '@px/shared/env';
import {SessionProviderFacade} from '@px/shared/session-provider';
import {appRoutes} from './app.routes';
import {AppApolloModule} from './modules/apollo/app-apollo.module';
import {InterceptorsModule} from './modules/interceptors/interceptors.module';
import {AppServiceWorkerModule} from './modules/service-worker/app-service-worker.module';
import {WEB_PLAYER_JPEG_ONLY} from '@px/pss/web-player';
import {PSSThemesPlatformEnvironment, PSSThemesPlatformFeatures} from '@px/pss-themes/platform-env';
import {PHOTOGRAPHER_BACKEND_CLIENT_ID} from '@px/photographer-auth/domain';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withPreloading(PreloadAllModules)),
    importProvidersFrom([
      BrowserAnimationsModule,
      AppApolloModule,
      AppTranslateModule,
      AppServiceWorkerModule,
      InterceptorsModule,
      ApiModule,
      PxEventsApiModule,
      SharedDataAccessBackendModule,
      ConnectionIssueDomainModule,
      PxEventsDomainModule,
    ]),
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: 'csrftoken',
        headerName: 'X-CSRFToken',
      })
    ),
    SessionProviderFacade,
    DEFAULT_STORAGE_SALT,
    {
      provide: LocalStorageService,
      useFactory: (): ILocalStorageService => new LocalStorageService(inject(LOCAL_STORAGE_SALT)),
    },
    WINDOW_PROVIDER,
    ConfigCatAsyncProvider,
    FEATURE_FLAGS_CONFIGURATOR_PROVIDER,
    CONFIG_CAT_ASYNC_INITIALIZER,
    DataDogService,
    DATA_DOG_SETUP_INITIALIZER,
    {provide: MATERIAL_SANITY_CHECKS, useValue: false},
    BrowserService,
    {
      provide: PX_EVENTS_API,
      useExisting: PxEventsGraphqlApiService,
    },
    {provide: BACKEND_CONTEXT, useExisting: BackendContextService},
    {
      provide: WEB_PLAYER_JPEG_ONLY,
      useFactory: () => inject(PSSThemesPlatformEnvironment).hasFeature(PSSThemesPlatformFeatures.JPEG_ONLY_IN_PLAYER),
    },
    {
      provide: PHOTOGRAPHER_BACKEND_CLIENT_ID,
      useFactory: () => inject(PSSThemesPlatformEnvironment).PX_OAUTH_CLIENT_ID,
    },
  ],
};
