import {inject, Injectable} from '@angular/core';
import {filter, map, Observable} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {IUserDataGql} from '../../interfaces/user-gql-data';
import {
  ICreateCustomerPreferencesInput,
  ICustomerPreferences,
} from '../../interfaces/customer-preferences-gql.interface';

@Injectable({providedIn: 'root'})
export class AuthGqlDataService {
  private readonly apollo = inject(Apollo);
  private readonly productFamily = inject(PRODUCT_FAMILY);

  private readonly userPreferencesQuery = gql<
    {customerPreferences: ICustomerPreferences},
    {productFamily: ProductFamily}
  >`
    query CustomerPreferences($productFamily: ProductFamily!) {
      customerPreferences(productFamily: $productFamily) {
        dateCreated
        gaCode
        isSharpeningOn
        displayName
        customDomain
        language
        metaData
        studioLogo {
          url
          type
        }
      }
    }
  `;

  private readonly createCustomerPreferencesMutation = gql<
    {createCustomerPreferences: ICustomerPreferences},
    {input: ICreateCustomerPreferencesInput}
  >`
    mutation CreateCustomerPreferences($input: CreateCustomerPreferencesInput!) {
      createCustomerPreferences(payload: $input) {
        displayName
        customDomain
        language
        gaCode
        metaData
        isSharpeningOn
        studioLogo {
          url
          type
        }
      }
    }
  `;

  private readonly userDataQuery = gql<IUserDataGql, {productFamily: ProductFamily}>`
    query GetUserData($productFamily: ProductFamily!) {
      myAccount {
        pxId
        email
        beaconSignature(productFamily: $productFamily)
        dateCreated
        isActive
        profile {
          firstName
          lastName
          websiteUrl
          studioName
          metaData
        }
        billingInfo {
          combinedProductLimits {
            PSS {
              isTrial
              isVideoDownloadPhotographerEnabled
              isVideoDownloadClientEnabled
              slideshowCountLimit
              isVideo4kAllowed
            }
            PSF {
              isTrial
              galleriesCountLimit
            }
          }
        }
      }

      customerSubscriptions(productFamily: $productFamily, status: ACTIVE) {
        nextBillingDate
        package {
          products {
            amount
            quantity
            price {
              metaData
              product {
                id
                name
                metaData
              }
            }
          }
        }
        metaData
      }

      mySlideshowsStatistics {
        activeSlideshowsCount
        deactivatedSlideshowsCount
        publishedActiveSlideshowsCount
        publishedDeactivatedSlideshowsCount
        samplePublishedSlideshowsCount
        sampleSlideshowsCount
      }

      myGalleriesStatistics {
        activeGalleriesCount
        deactivatedGalleriesCount
        publishedActiveGalleriesCount
        publishedDeactivatedGalleriesCount
        samplePublishedGalleriesCount
        sampleGalleriesCount
      }
    }
  `;

  getCustomerPreferences(): Observable<ICustomerPreferences> {
    return this.apollo
      .query({
        query: this.userPreferencesQuery,
        variables: {
          productFamily: this.productFamily,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(r => !!r.data.customerPreferences),
        map(r => r.data.customerPreferences)
      );
  }

  createCustomerPreferences(): Observable<ICustomerPreferences> {
    return this.apollo
      .mutate({
        mutation: this.createCustomerPreferencesMutation,
        variables: {
          input: {
            productFamily: this.productFamily,
          },
        },
      })
      .pipe(
        filter((r): r is {data: {createCustomerPreferences: ICustomerPreferences}; loading: boolean} => !!r.data),
        map(r => r.data.createCustomerPreferences)
      );
  }

  getUserData(): Observable<IUserDataGql> {
    return this.apollo
      .query({
        query: this.userDataQuery,
        variables: {
          productFamily: this.productFamily,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .pipe(
        filter(r => !!r.data),
        map(r => r.data)
      );
  }
}
