import {Observable} from 'rxjs';
import {IApiResponse} from './api-response';
import {IUser} from './user';
import {IS3Credentials} from './s3-credantials.interface';
import {Primitive} from 'ts-essentials';

export interface IAuthService {
  logInCheck(noCache?: boolean): Observable<IApiResponse<IUser>>;
  jwtAuthorize(
    payload: Record<'expiration' | 'jwt' | 'refresh_id', Primitive>,
    scope?: 'sld' | 'gal'
  ): Observable<IApiResponse<{user: Pick<IUser, 'id'>}>>;
  signS3(): Observable<IApiResponse<IS3Credentials>>;
  logOut(): Observable<void>;
  dropAuthCache(): void;
}
