import {inject, Injectable} from '@angular/core';
import {Observable, retry} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PlatformEnvironment} from '@px/shared/env';
import {IUser} from '../interfaces/user';
import {IAuthService} from '../interfaces/auth-service.interface';
import {IApiResponse} from '../interfaces/api-response';
import {IS3Credentials} from '../interfaces/s3-credantials.interface';
import {HttpCacheManager, withCache} from '@ngneat/cashew';

@Injectable({providedIn: 'root'})
/**
 * @deprecated use domain
 */
export class AuthService implements IAuthService {
  private static readonly xPxScope = 'x-px-scope';
  private readonly userCacheKey = 'user';
  private readonly manager: HttpCacheManager | null = inject(HttpCacheManager, {optional: true});
  protected readonly http = inject(HttpClient);
  protected readonly platform = inject(PlatformEnvironment);

  logInCheck(noCache?: boolean): Observable<IApiResponse<IUser>> {
    const url = `${this.platform.API_URL}check-login/`;

    return this.http.get<IApiResponse<IUser>>(url, {
      context: withCache({
        key: this.userCacheKey,
        cache: !noCache,
      }),
    });
  }

  jwtAuthorize(payload: Record<string, unknown>, scope?: string): Observable<IApiResponse<{user: Pick<IUser, 'id'>}>> {
    const url = `${this.platform.API_URL}jwt-authorization/`;

    return this.http
      .post<IApiResponse<{user: Pick<IUser, 'id'>}>>(url, payload, {
        headers: scope ? {[AuthService.xPxScope]: scope} : {},
      })
      .pipe(retry(2));
  }

  signS3(): Observable<IApiResponse<IS3Credentials>> {
    return this.http.get<IApiResponse<IS3Credentials>>(`${this.platform.API_URL}sign-s3-request/`);
  }

  logOut(): Observable<void> {
    const url = `${this.platform.API_URL}logout/`;

    return this.http.post<void>(url, {});
  }

  dropAuthCache(): void {
    this.manager?.delete(this.userCacheKey);
  }
}
