import {inject, Injectable} from '@angular/core';
import {Product} from '@px/shared/data-access/product-product-family';
import {PlatformEnvironment} from '@px/shared/env';
import {PhotographerAuthFacade} from './photographer-auth-facade.service';

@Injectable()
export class PhotographerNavigationFacadeService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly photographerAuthFacade = inject(PhotographerAuthFacade);

  authCodeUrl(product: Product, state = '/'): string {
    const clientId = this.platform.PX_PRODUCTS_CLIENT_ID?.[product];
    const session = this.photographerAuthFacade.getSessionToken();

    return `${this.platform.AUTH_API_URL}/client?client_id=${clientId}&state=${encodeURIComponent(state)}${session ? `&session=${session}` : ''}`;
  }
}
