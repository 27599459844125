export interface IUserDataGql {
  myAccount: {
    beaconSignature: string | null;
    dateCreated: string;
    email: string;
    pxId: number;
    profile: {
      websiteUrl: string | null;
      studioName: string;
    };
    billingInfo: {
      combinedProductLimits: {
        PSS: {
          isTrial: boolean;
          isVideoDownloadPhotographerEnabled: boolean;
          isVideoDownloadClientEnabled: boolean;
          slideshowCountLimit: number;
          isVideo4kAllowed: boolean;
        };
        PSF: {
          isTrial: boolean;
          galleriesCountLimit: number;
        };
      };
    };
  };
  customerSubscriptions: {
    package: {
      products: {
        price: {
          product: {
            id: string;
            name: string;
          };
        };
      }[];
    };
  }[];
  mySlideshowsStatistics: {
    activeSlideshowsCount: number;
    deactivatedSlideshowsCount: number;
    publishedActiveSlideshowsCount: number;
    publishedDeactivatedSlideshowsCount: number;
    samplePublishedSlideshowsCount: number;
    sampleSlideshowsCount: number;
  };
  myGalleriesStatistics: {
    activeGalleriesCount: number;
    deactivatedGalleriesCount: number;
    publishedActiveGalleriesCount: number;
    publishedDeactivatedGalleriesCount: number;
    samplePublishedGalleriesCount: number;
    sampleGalleriesCount: number;
  };
}
