import {ClassProvider, ErrorHandler, inject, Injectable, Injector} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, finalize, Observable, retry, Subject, switchMap, throwError} from 'rxjs';
import {PlatformEnvironment} from '@px/shared/env';
import {Operation} from '@apollo/client';
import {SessionProviderFacade} from '../application/session-provider.facade';
import {ISessionProvider} from '../entities/session-provider-service.interface';
import {AUTH_HEADER_FORMAT} from './auth-header-format.token';

@Injectable({providedIn: 'root'})
export class GqlTokenRefreshInterceptorService implements HttpInterceptor {
  private queue: Subject<HttpEvent<unknown> | undefined>[] = [];
  private isRefreshing = false;

  protected readonly sessionProviderFacade = inject(SessionProviderFacade);
  protected readonly platform = inject(PlatformEnvironment);
  protected readonly injector = inject(Injector);

  protected readonly authHeaderFormat = inject(AUTH_HEADER_FORMAT, {optional: true});

  private isSecureOperation(req: HttpRequest<Operation>): boolean {
    return Array.isArray(req.body)
      ? req.body.some(item => this.platform.isGQLOperationWithHeader(item.operationName))
      : this.platform.isGQLOperationWithHeader(req.body?.operationName);
  }

  private isGQLEndpoint(req: HttpRequest<unknown>): req is HttpRequest<Operation> {
    return req.url.startsWith(this.platform.GRAPH_QL_ENDPOINT);
  }

  protected addHeaders(
    service: ISessionProvider | undefined,
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = service?.getSessionToken();
    const value = `Bearer ${token}`;
    const headers = token
      ? req.headers.set('Authorization', this.authHeaderFormat?.(value, req) ?? value)
      : req.headers;

    return next.handle(req.clone({headers}));
  }

  protected toIntercept(req: HttpRequest<unknown>): boolean {
    return this.isGQLEndpoint(req) && this.isSecureOperation(req);
  }

  protected getRefreshingChain(): Observable<void> {
    const service = this.sessionProviderFacade.getSessionService();

    return service?.refresh() ?? throwError(() => new Error("SessionProvider doesn't exist!"));
  }

  protected isSessionExpired(): boolean {
    const service = this.sessionProviderFacade.getSessionService();

    return service?.isSessionExpired() ?? false;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const service = this.sessionProviderFacade.getSessionService();

    if (this.toIntercept(req)) {
      if (!service) {
        console.error("SessionProvider doesn't exist!");
      }

      if (!this.isSessionExpired()) {
        return this.addHeaders(service, req, next);
      }

      const subject$ = new Subject<HttpEvent<unknown> | undefined>();

      this.queue.push(subject$);

      if (!this.isRefreshing) {
        this.isRefreshing = true;

        this.getRefreshingChain()
          .pipe(
            retry(1),
            catchError(err => {
              console.error(err);
              service?.logOut();
              return throwError(() => err);
            }),
            finalize(() => (this.isRefreshing = false))
          )
          .subscribe({
            next: () => {
              while (this.queue.length) {
                const deferred = this.queue.shift();

                deferred?.next(undefined);
                deferred?.complete();
              }
            },
            error: (error: HttpEvent<unknown>) => {
              this.injector.get(ErrorHandler).handleError(error);
              while (this.queue.length) {
                const deferred = this.queue.shift();

                deferred?.error(error);
                deferred?.complete();
              }
            },
          });
      }

      return subject$.pipe(switchMap(() => this.addHeaders(service, req, next)));
    }

    return next.handle(req);
  }
}

export const GQL_TOKEN_INTERCEPTOR: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: GqlTokenRefreshInterceptorService,
  multi: true,
};
