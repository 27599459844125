import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface ICustomerPreferences {
  displayName: string;
  dateCreated: string;
  customDomain: string | null;
  language: string;
  metaData: {
    tpp_shown?: boolean;
    lastChosenTransitionDuration?: number;
    lastChosenTransitionType?: number;
  };
  isSharpeningOn: boolean;
  gaCode: string | null;
  studioLogo: {url: string; type: 'LIGHT' | 'DARK'}[];
}

export interface ICreateCustomerPreferencesInput {
  productFamily: ProductFamily;
}
